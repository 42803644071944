



















































// @ts-ignore -- this doesn't have typings so implicit 'any' will have to be ok.
import * as VueGridLayout from "vue-grid-layout";

import { vxm } from "@/store";
import { Component, Vue } from "vue-property-decorator";
import OkModal2 from "@shared_vue/components/OkModal.vue";
import GenericGridCard from "@/views/dashcards/GenericGridCard.vue";


@Component({
  components: {
    GenericGridCard,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    OkModal2
  },
})
export default class Dashboard extends Vue {
  private ui = vxm.ui;
  private showSuccess = true;
  private acked=false;
  private readonly Cards = [
    { i: "0", x: 0, y: 0, w: 2, h: 8, title: "Users", description: "Manage Users", route:"/dashboard/users" },
  ];
  private readonly layoutKey = "admin-dash-layout";
  private dragging: boolean = false;
  private storedLayout = JSON.parse(localStorage[this.layoutKey] || null);

  private layout = this.storedLayout || this.Cards;
  private gridKey = 0;

  private saveGrid() { //move this to the ui store :-O
    this.storedLayout = this.layout;
    localStorage.setItem(this.layoutKey, JSON.stringify(this.layout));
  }

  private resetLayout(type: any) {
    this.gridKey++;
    this.layout = type === "base" ? this.Cards : this.storedLayout;
  }

  private noDrag() {
    this.ui.setDragging(false);
  }
  private moveEvent(i: any, newX: any, newY: any): any {
    this.ui.setDragging(true);
  }

  private movedEvent(i: any, newX: any, newY: any): any {
    setTimeout(this.noDrag, 250);
  }
}
