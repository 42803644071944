








import {vxm} from "@/store";
import {Component, Prop, Vue} from 'vue-property-decorator';


@Component({

})

export default class GenericGridCard extends Vue {
  @Prop(String) readonly route: string | undefined;
  @Prop(String) readonly title: string | undefined
  @Prop(String) readonly description: string | undefined

  private ui = vxm.ui;

  private navigate() {
    if (!this.ui.dragging) {
      this.$router.push(this.route!);
    }
  }
}
